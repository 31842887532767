import React from 'react'

import Layout from '~components/Layout'
import ProductHero from '~components/ProductHero'
import ProductDescription from '~components/ProductDescription'
import ProductService from '~components/ProductService'
import Question from '~components/Question'
import Reviews from '~components/Reviews'
import Partners from '~components/Partners'
import S from '~components/seo'
import useSnapScroll from '~hooks/useSnapScroll'

import * as s from '../style.module.scss'

const Terminal = (props) => {
  const {
    header,
    headerMobileExtras,
    footer,
    socials,
    cellar,
    contactInfo,
    cookiesOffcanvas,
    bookingForm,
    bookADemo,
    formMessages,
    seo,
    sectionHero,
    sectionReviews,
    sectionSlides,
    sectionIntermission,
    sectionPartners,
    sectionContactForm,
    contactUsForm,
    reviews,
  } = props

  useSnapScroll('.section-snap')

  return (
    <Layout
      mode="secondary"
      withBooking
      withCta
      {...{
        header,
        headerMobileExtras,
        footer,
        socials,
        cellar,
        contactInfo,
        cookiesOffcanvas,
        bookingForm,
        bookADemo,
        formMessages,
        contactUsForm,
        sectionContactForm,
      }}
    >
      <S {...seo} />

      {sectionHero && <ProductHero {...sectionHero} variant="terminal" />}

      {sectionHero?.textParagraph && (
        <ProductDescription descr={sectionHero.textParagraph} />
      )}

      <div className={s.product_content}>
        {sectionSlides?.slice(0, 3)?.map((slideGroup, index) => (
          <ProductService
            key={slideGroup?.heading}
            {...slideGroup}
            button={slideGroup?.link?.text}
            isReverced={index % 2}
            className="section-snap"
          />
        ))}

        {sectionIntermission && (
          <Question {...sectionIntermission} className="section-snap" />
        )}

        {sectionSlides?.slice(3)?.map((slideGroup, index) => (
          <ProductService
            key={slideGroup.title}
            {...slideGroup}
            button={slideGroup?.link?.text}
            isReverced={(index + 1) % 2}
            className="section-snap"
          />
        ))}
      </div>

      {sectionReviews && (
        <Reviews {...sectionReviews} reviews={reviews.nodes} />
      )}
      {sectionPartners && <Partners {...sectionPartners} />}
    </Layout>
  )
}

export default Terminal
